<template>
    <transition name="fade">
        <div class="row center ch pointer click" @click.prevent="click">
            <div class="checkbox" :class="{active: modelValue || active}"></div>
            <span v-if="$slots.default" style="user-select: none; font-size: 13px; margin: 0 12px 0 8px;"><slot></slot></span>
        </div>      
    </transition>
</template>
<script>

/*

    <Check v-model="item.readonly" title="Compact" @update="update()"></Check>
 
*/

export default {
    data: function () {return {
        // value: Boolean,
    }},
    props:{
        modelValue: Boolean,
        active: Boolean,
    },
    mounted() {
        // this.value = this.active
    },
    emits: ["update:modelValue"],
    methods: {
        click(){            
            this.$emit('update:modelValue', !this.modelValue)
            this.$emit('update', true);
        }
    },
}
</script>

<style scoped>
   .checkbox{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        background: var(--g1);  
        border-top: 1px solid var(--g1);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
        /* transition: all 0.1s ease; */
   }

   .checkbox:active{
        /* transform: translate(0px, 1px); */
   }
   .checkbox:hover{
    background: var(--g15);  
    
   }

   .checkbox.active{
        background: var(--special);
        border-top: 1px solid var(--g3);
   }
   
   .checkbox.active::before{
    display: flex;
    align-items: center;
    justify-content: center;
        content: url('data:image/svg+xml,<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4.19996L3.54548 7L9 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        position: absolute;
        top: -5px;
        left: 3px;
    }
    
</style>

