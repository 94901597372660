<template>


    <Fix>
        <Head/>
        <router-view :key ='$route.params'></router-view>
    </Fix>


    <!-- <box>
        <Head/>
        <div class="initbox"><router-view></router-view></div>
        <TabMenu icons/>
        <Footer/>
    </box> -->
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'App',
    data() {return {}},
    mounted() {
        document.querySelector('body').classList.add('dark')
    },

    });
</script>
<style>
    @import '@/css/x_reset.css';
    @import '@/css/base.css';
    @import '@/css/body.css';
    @import '@/css/colors.css';
    @import '@/css/input.css';
    @import '@/css/tab.css';
    @import '@/css/tag.css';
    @import '@/css/text.css';
    @import '@/css/sizes.css';
    @import '@/css/table.css';
    @import '@/css/padding.css';    
    @import '@/css/buttons.css';
    @import '@/css/transitions.css';
    @import '@/css/local.css';
    
    

    .initbox{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100vw;
        height:100vh; 
    }

</style>