// <p v-drop="uploadHandler(files)"></p>
const Outside = {

  mounted(el, binding, vnode) {
    
    const handler = (e) => {
      if (!el.contains(e.target) && el !== e.target) binding.value(e, el);      
    }

      el.out = handler      
      document.body.addEventListener('click', handler)      
  },
 
  unmounted(el) {document.body.removeEventListener('click', el.out)}
}
export default Outside
