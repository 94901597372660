<template>
  
    
 
    <div class="column left" style="width: 100%;">

        <!-- type -->
        <Block title="Type" sub="How user have to login with" must>
          <div class="row">
              <p class="tab" :class="{active: item.type == i}" @click="item.type = i" v-for="(i, index) in ['news','auctions']" :key="index">{{ i }}</p>
          </div>
        </Block>
        
        
        <!-- provider -->
        <Block title="Language" sub="How user have to login with" must>
          <div class="row">
              <p class="tab" :class="{active: item.lang == i}" @click="item.lang = i" v-for="(i, index) in ['en','it','ru']" :key="index">{{ i }}</p>
          </div>
        </Block>


            <!-- title -->
            <Block title="Title" must>
              <input class="nomargin" type="text" placeholder="Bitcoin goes up" v-model="item.title">
            </Block>
            
            <!-- line -->
            <Block title="Line" must>
              <input class="nomargin" type="text" placeholder="Bitcoin goes up" v-model="item.line">
            </Block>
            
            <!-- line -->
            <Block title="Stima comment">
              <input class="nomargin" type="text" placeholder="Bitcoin goes up" v-model="item.stima">
            </Block>
            
            <!-- text -->
            <Block title="Text" must>
              <textarea style="min-height: 100px;" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' v-model="item.text"></textarea>
            </Block>
            
            <!-- tags -->
            <!-- <Block title="Tags">
              <input class="nomargin" type="text" placeholder="btc, eth..." v-model="pre.tags">
            </Block> -->

            <!-- image -->
            <Block title="Image" must>
              <Image nopreview v-upload.jpg.png="upload" v-if="pre.image"  :src="pre.image" />
              <p class="tag"  v-upload.jpg.png="upload">Upload</p>
            </Block>
            
                  
            <Block title="Settings">
                <div class="row">
                  <Switch style="margin-right: 22px;" title="User can login into platform" v-model="item.active">Active</Switch>
                  <Switch style="margin-right: 22px;" title="User email is verified" v-model="item.pin">Pin</Switch>
                  <!-- <Switch v-model="item.kyc" title="User is aprooved (Know Your Customer)">KYC</Switch>               -->
                </div>
            </Block>
            
        
            <!-- errors -->
            <Block v-if="errors.length" >
              <p class="bad" v-for="(item, index) in errors" :key="index" style="color: var(--bad); margin: 4px 0 ;" >• {{ item }}</p>
            </Block>
          
            <!-- buttons -->
            <hr>            
            <div class="column">
              <p class="button" @click="create()">Create</p>
              <br>
            </div>
            


      </div>


    <!-- userDone -->   
    <Popup v-model="showDone" done style="z-index: 1000000;">
        <!-- <template #icon><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45 17.5L41.475 13.975L25.625 29.825L29.15 33.35L45 17.5ZM55.6 13.975L29.15 40.425L18.7 30L15.175 33.525L29.15 47.5L59.15 17.5L55.6 13.975ZM1.025 33.525L15 47.5L18.525 43.975L4.575 30L1.025 33.525Z" fill="#2CE833"/></svg></template> -->
        <template #title>Done!</template>
        <template #line>News has been created! Now you can find it in news page.</template>        
          <div class="row center">
            <p class="button" @click="done">OK</p>
          </div>
    </Popup>


    
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({  
  mounted() {          
    if (this.dev) this.inits()
  },
  data() {
    return {    
      id: undefined,        
      
      showDone: false,
      errors:[],      

      pre:{
        image: undefined,        
        imagefile: undefined,        
        tags: ''
      },
      
      item: {
        pin: false, //bool 
        active: false, //bool 
        type: 'news', //string en, ru
        lang: 'en', //string en, ru
        title: '', //string 
        line: '', //string 
        stima: '', //string 
        text: '', //string markdown
        image: '', //string 
        tags: [], //[]string
      },

      busy: false,
    }
  },
  
  methods: {

    inits(){
      this.item = {
        pin: false, //bool 
        active: true, //bool 
        type: "news", //string en, ru
        lang: "en", //string en, ru
        title: "Bitcoin goes up", //string 
        line: "This summer bitcoin beat the records", //string 
        stima: "Stima thinks it good!", //string 
        text: "To submit new apps to the App Store, you need to let us know whether or not you’re a trader. The Digital Services Act in the EU requires Apple to verify and display trader contact information for all traders distributing apps on the App Store in the EU. Learn More", //string markdown
        image: "", //string 
        tags: ['btc','bitcoin','bull'], //[]string         
      }
    },

   
    // create user
    async create(){
      this.errors = []

      if (!this.item.title) this.errors.push('Title is empty')              
      if (!this.item.line) this.errors.push('Line is empty')              
      if (!this.item.text) this.errors.push('Text is empty')              
      if (!this.pre.image) this.errors.push('Image is empty')              
      
      if (this.errors.length) return
      
      this.item.image = await this.uploadImage(this.pre.imagefile)
      
      let res = await this.api.send('news/create',this.item)
      console.log('ress',res)
      if (res.error){
        this.errors.push(`${res.error.code}: ${res.error.id} ${res.error.comment}`)
        return
      }

      console.log("new news", res.resp)

      //user
      this.item = res.resp
      this.showDone = true
      this.done()
    },

    async upload(v){
      let file = v[0]
      console.log(file)       
      this.pre.image = await this.tools.readFileAsBase64(file)
      this.pre.imagefile = await this.tools.readFileAsBytes(file)
      
    },
    
    async uploadImage(body){
      if (!body) return
      let res = await this.api.upload.image(body)
      if (res.error){
        console.error(res)
        return
      }
      console.log(res)
      return res.resp.link      
    },

    done(){
      this.showDone = false;
      this.$emit('update', this.user)
    },


    
  },
});

</script>
<style scoped>

</style>