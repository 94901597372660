<template>
   <div 
    style="position: relative" 
    :style="{
      background: value || modelValue || 'var(--g1)', 
      width: width || '32px', 
      height: height || '32px', 
      borderRadius: radius || '100px',
      border: border || '1px solid var(--g1)'
    }">
        
        <input style="opacity: 0; width: 32px; height: 32px" type="color" :value="value || modelValue"
            @input="update($event.target.value)" >
    </div>

    <!-- 
        <Color width="32px" height="32px" vmodel="color" @update="change"/>
     -->
    
</template>
<script>
  export default {
    props: {      
      width: String,
      height: String,
      radius: String,
      border: String,
      value: String,
      modelValue: String
    },
    computed: {},
    data () {return {
      
    }},
    methods:{
      update(v) {
        this.$emit('update:modelValue', v)
        this.$emit('update', v)        
        console.log(v)
      },
    },
  }
</script>