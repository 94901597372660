<template>
     <div class="column inputBox" style="position: relative;z-index: 10000; ">
          
          <div ref="input" class="pointer click row between" style="position: relative;" @click="open = !open">
             <p>{{ titles[modelValue] || current.title || 'Select...' }} </p> 
            <span class="t2">▾</span> 
          </div>
          
          <!-- list -->
          <!-- marginTop: `${$refs.input.clientHeight}px` -->
          <!-- v-out="open = false" -->
            <div class="selectItems" :style="{height}" v-if="open">                      
              <div v-for="(listitems, index) in list" :key="index">
                <h4 class="desc">{{ index }}</h4>
                <div class="row ch item" @click="current = item; update()" v-for="(item, index) in listitems" :key="index">                
                  <img v-if="item.icon" class="icon" :src="item.icon">
                  <div class="column">
                      <p>{{ item.title }}</p>
                      <p v-if="item.desc" class="mini">{{item.desc }}</p>
                  </div>
                </div>

              </div>
              
            </div>

        </div>

        <!-- 

          <Select v-model="type" :items="[{id, icon, title, desc, group}]" @update="item" class="w300" height="300px">{{title}}</Select>
         -->
</template>
<script>
export default {
   
    data: function () {return {
      current:{},
      open: false,             
    }},
    
    props: {
      modelValue: String, 
      top:Boolean,
      items: [],
      height: {type:String, default:'300px'},      
      // delay: {type: Number, default:0},
    },

    computed: {
      list(){
        let groups = {}        
        this.items.forEach(item => {            
            if (!groups[item.group] ) groups[item.group] = []
            groups[item.group].push(item)            
        });
        console.log('groups',groups)
        return groups
        
      },
      titles(){
        let list = {}
        this.items.forEach(item => list[item.id] = item.title);
        return list
      },
    },

    methods: {      
       update() {
        this.$emit('update:modelValue', this.current.id)  
        this.$emit('update', this.current)
        this.open = false
      },

    },
}
</script>

<style scoped>
    /* .inputBox{
      margin: 0px 0 12px;
      margin: 0px 0 0px;
    } */
/* input */
.selectItems{  
  display: flex;
  flex-direction: column;
  overflow-y: scroll; 
  overflow-x: hidden;
  background-color: var(--back); 
  position: absolute; 
  z-index: 10000; 
  /* margin-top: 30px; */
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px dashed var(--g1);
  box-shadow: var(--popupShadow);
  border-radius: 10px;
  padding: 10px;
}

.item{  
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  /* background-color: var(--back); */
  background: var(--input);
  
}
.item:hover{
  background-color: var(--g1);
}

/* .autocomplete div p{
  padding: 2px 8px;
  text-align: left;
} */

.noBottomBorder{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;  
}

  .text{
    width: 100%;
    position: relative;
    padding: 0px 0;
    margin: 0;
  }

  .input{
    padding: 5px 12px 8px;      
    border-radius: 8px;
    background: var(--g05);
  }
  
  .clear{
    background:none;
  }

  .inputIcon{
    padding-left: 50px;      
  }

  .icon{
    height: 20px;
    width: auto;
    position: absolute;
    top: 11px;
    left: 12px;
    border-radius: 4px;
  }
  .clearicon{
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    position: absolute;
    top: 11px;
    right: 7px;
    border-radius: 100px;
    background-color: var(--g1);
    
  }
  .clearicon span{
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    color: var(--g3);
    margin-top: -1px;
  }

  .iconpadding{
    padding-left: 30px;
  }

</style>

