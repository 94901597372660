<template>
     <div ref="box" class="row inputBox" :style="{minWidth: min}" style="position: relative;">
      
          <input
              style="width: 100%; margin: 0; transition: none; padding-right: 0px;"
              ref="input"              
              type="number"
              v-model.number="year"
              :placeholder="placeholder || 'Year'"   
              @blur="update"
              @input="$refs.box.style.width = ($event.target.value.length + 7) + 'ch'">

              <p class="label">▾</p>

          <select v-model="year" class="selects debug" @change="update">
            <option v-for="(item, index) in 1000" :key="index" :value="currentYear - item">{{ currentYear - item}}</option>
          </select>
                  
      </div>

        <!-- 
          <Year v-model="year" placeholder="Year" class="w300"/>
         -->
</template>
<script>
export default {
   
    data: function () {return {
      year: this.modelValue,      
    }},

    props: {
      modelValue: Number,
      placeholder: String,
      min:{type: String, default:'100px'}
    },

    mounted() {
    //  
    },

    computed: {
      currentYear(){
        let d = new Date()
        return d.getFullYear()
      }
    },

    methods: {

       update() {
        if (isNaN(this.year)) return
        this.$emit('update:modelValue', this.year)          
      },
      

    },
}
</script>

<style scoped>
.label{
  position: absolute;
right: 12px;
top: 11px;
font-family: monospace;
font-weight: 700;
font-size: 14px;
padding-left: 9px;
border-left: 0.5px solid var(--g1);

}
.selects{
  position: absolute;
right: 0px;
height: 100%;
border-radius: 0;
width: 55px;
width: -moz-fit-content;
width: fit-content;
padding: 0px;
background: none;
opacity: 0;
}

    .inputBox{
      /* margin: 0px 0 12px; */
      /* margin: 0px 0 0px; */
    }

  .input{
    padding: 5px 12px 8px;      
    border-radius: 8px;
    background: var(--g05);
  }
  
  .clear{
    background:none;
  }

  .inputIcon{
    padding-left: 50px;      
  }

  .icon{
    height: 20px;
    width: auto;
    position: absolute;
    top: 11px;
    left: 12px;
    border-radius: 4px;
  }
  .clearicon{
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    position: absolute;
    top: 11px;
    right: 7px;
    border-radius: 100px;
    background-color: var(--g1);
    
  }
  .clearicon span{
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    color: var(--g3);
    margin-top: -1px;
  }

  .iconpadding{
    padding-left: 30px;
  }

</style>

