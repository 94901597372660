<template>
  
  <div class="column pointer click center w100" style="position: relative; margin: 10px;">
        <img class="mb3" :src="icon" :style="{maxWidth: width, maxHeight: height}">
        <p class="fileTitle">{{ title }}</p>
        <p class="fileDesc">{{ info }}</p>
        <span class="closeIcon pointer click" @click="remove"></span>        
    </div>          
    
</template>
<script>


/*

    <Title @plus="update">Attributes<template #desc>Desc about</template></Title>
 
*/

export default {
    data: function () {return {
        preview: false,
    }},
    props:{
        close: Boolean,
        width:{type:String, default:'65px'},
        height:{type:String, default:'65px'},
        file: {},
    },    
    computed: {
      icon(){
        let ext = this.tools.ext(this.file.name)
        ext = ext.toUpperCase()
        return `/images/icons/file/${ext || 'TXT'}.svg`        
      },
      title(){
        return this.file.name
      },
      info(){
        return this.tools.size( this.file.size)
      },
    },
    methods: {
      remove(){
            this.$emit('close', true);
        }
    },
}
</script>

<style scoped>
.fileTitle {
  font-size: 11px;
  word-break: break-all;
  text-align: center;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10ch;  */

  overflow: hidden;
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 14px;
  color: var(--g5);
}
.fileDesc {
  font-size: 10px;
  color: var(--g2);
}


.closeIcon {
  width: 22px;
  height: 22px;
  background: #222222;
  position: absolute;
  top: 0;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}

.closeIcon:hover {
  background: #a74c4c;
}

.closeIcon::after {
  content: '×';
  color: var(--g5);
  font-size: 13px;
  font-weight: 600;
  margin-top: -1px;
}

.closeIcon:hover::after {
  color: var(--g9);
}
</style>

