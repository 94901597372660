<template>

<Column full border class="mainmenu" padding="20px 10px">

  <Block :title="tools.title(i)" v-for="(dept, i) in db.stats.db" :key="i">
   
    <table class="w300">
    <tr v-for="(item, index) in dept" :key="index">
      <td>{{ tools.title(index) }}</td>
      <td>{{ item }}</td>
    </tr>
   </table>
  </Block>
  
</Column>


<Column full border class="mainmenu" padding="20px 10px">

  <!-- db.stats.db -->
  
</Column>




<!-- menu -->
<!-- <Column style="width: 100%;">
  
  
  <box>
  <div class="column center" style="height: 100vh;">
    <svg width="134" height="154" viewBox="0 0 134 154" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M88.3722 64.3446L66.4978 76.9936L0 38.5467V38.5195L66.5962 0.000350952L133.102 38.4796L98.4032 58.5441L69.1041 41.5905C67.9556 40.991 66.6203 40.8586 65.3767 41.2209C64.1331 41.5832 63.0771 42.4123 62.4289 43.5353C61.7807 44.6582 61.5903 45.9884 61.8974 47.2486C62.2045 48.5087 62.9855 49.6014 64.0774 50.299L88.3722 64.3446Z" fill="var(--primary)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M66.4978 153.942L66.5966 153.999L133.171 115.469V90.2431L88.3449 64.3277L66.4978 76.96V102.183L69.1045 103.69C70.1964 104.387 70.9774 105.48 71.2845 106.74C71.5916 108 71.4012 109.331 70.753 110.453C70.1048 111.576 69.0488 112.406 67.8052 112.768C67.3765 112.893 66.9369 112.959 66.4978 112.967V153.942ZM133.076 38.4636L98.3763 58.5274L133.171 78.661V38.5186L133.076 38.4636Z" fill="var(--primary)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 115.491L66.5962 153.999L66.629 153.98V112.963C65.7505 113.003 64.8695 112.812 64.0774 112.398L0 75.3487V115.491ZM66.629 102.259L0 63.7447V38.5186L0.0729847 38.4764L66.629 76.96V102.259Z" fill="var(--primary)"/>
    </svg>
    <hr>
    <h1>STIMA</h1>
    <p class="desc"> {{ today() }}</p>  
  </div>  
</box>
</Column> -->


    
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  mounted() {
    // 
  },
  data() {
    return {
      
    }
  },
  methods: {
   today(){
      var options = {year: 'numeric', month: 'long', day: 'numeric' };
      var today  = new Date();            
      return today.toLocaleDateString("en-US", options)    
    },

    
    
  },
});
</script>
<style scoped>
.subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.0em;
  color: var(--g1);
  margin: 10px 0;
  /* margin-left: -5px; */

}

.tabs {
  margin: 0 10px;
}
</style>