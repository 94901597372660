<template>

    <div style="width: 100%;">
        <p v-if="simple && !to" class="menu" :class="{active: active, desc:desc, pointer:pointer}">
            <slot></slot>
            <span class="count" v-if="count">{{count}}</span>
        </p>
        
        <p v-if="!simple && !to" class="menu" :class="[on ? 'on':'off',{active: active, desc:desc, pointer:pointer}]">
            <slot></slot>
            <span class="count" v-if="count">{{count}}</span>
        </p>

        <router-link v-if="simple && to" class="menu" :class="{active: active, desc:desc, pointer:pointer}" :to="to">
            <slot></slot>
            <span class="count" v-if="count">{{count}}</span>
        </router-link>

        <router-link v-if="!simple && to" class="menu" :class="[on ? 'on':'off',{active: active, desc:desc, pointer:pointer}]" :to="to">
            <slot></slot>
            <span class="count" v-if="count">{{count}}</span>
        </router-link>
    </div>

</template>
<script>
export default {
    data: function () {return {
        
    }},
    props:{
        active:Boolean,
        simple:Boolean,
        on:Boolean,
        count:String,
        to: undefined,
        link: String,
        desc: Boolean,
        pointer: Boolean,
    },
    
    methods: {
       
    },  
    computed: {
       

    },
}
</script>
<style scoped>

.menu{
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 13px;
  color: var(--g7);
  padding: 5px 12px 5px 10px;
  line-height: 15px;
  /* margin: 0px 10px; */
  letter-spacing: -0.02em;
  cursor: default;
  user-select: none;
  border-radius: 7px;
  /* cursor: pointer; */
}


.menu.on::before{
  /* display: block; */
  /* content: url('data:image/svg+xml,<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="3" fill="%2333B448"/></svg>'); */
  content: '▪';
  color: rgba(0, 255, 37, 0.62);
  color: var(--special);
  /* font-size: 7px; */
  margin-right: 7px;
  /* height: 12px; */
  margin-left: -3px;
}

.menu.off::before{
  /* display: block; */
  /* content: url('data:image/svg+xml,<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="3" fill="%2333B448"/></svg>'); */
  content: '▪';
  color: var(--g1);
  /* font-size: 7px; */
  margin-right: 7px;
  /* height: 12px; */
  margin-left: -3px;
}

.count{
  font-size: 11px;
  color: var(--g4);  
  margin: 0 0px 0 8px;
  /* cursor: pointer; */
  width: 100%;
  user-select: none;
}

.menu:hover{
  /* background-color: var(--g01); */
  /* opacity: .4; */
  /* transition: all 0.1s ease; */
  color: var(--g9);
  /* background-color: var(--g01); */
}
.menu.active{
  width: 100%;
  color: var(--g9);
  /* background-color: var(--primary); */
  background-color: var(--g1);
}

.menu.router-link-active{
    width: 100%;
  background-color: var(--g1);
  color: var(--g9);  
  /* background-color: var(--primary); */
}


.menu.desc{
  color: var(--g3);  
}
.menu.desc:hover{
  color: var(--g3);  
}
.menu.desc.active{
  color: var(--g3);  
}
.pointer{
  cursor: pointer;
}
.menu.desc.active{
  color: var(--g6);  
}
</style>




















