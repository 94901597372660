<template>
   <span style="margin: 0;padding: 0; margin-right: 4px;" :style="{color: active ? 'var(--good)' : 'var(--g1)'}">●</span>    
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        active: Boolean,
    },    
}
</script>
<style scoped>
    
</style>