<template>
  
    
 
    <div class="column left" style="width: 100%;">

        <!-- provider -->
        <Block title="Provider" sub="How user have to login with" must>
          <p v-if="dev">{{ user.provider  }} {{ isNaN(user.provider) }}</p>
          <code v-if="dev">{{ user }}</code>
          <div class="row">
              <p class="tab" :class="{active: user.provider == index}" @click="user.provider = Number(index)" v-for="(item, index) in dict.providers" :key="index">{{ item }}</p>
          </div>
        </Block>

        <div v-if="user.provider != 3">

            <!-- email -->
            <Block title="Email" must>
              <input class="nomargin" type="email" placeholder="some@gmail.com" v-model="user.email">
            </Block>
            
            <!-- password -->
            <Block v-if="user.provider == 2" title="Password" sub="Password must be 8 chars lenght" must>        
              <input class="nomargin" type="text" v-model="user.pass">          
            </Block>
                  
            <!-- location -->
            <Block title="Location" must>
              <Geo v-model="user.geoid" placeholder="Location" class="w300 nomargin" height="300px"/>        
            </Block>

            <!-- username -->
            <Block title="Username">
              <div class="column" style="position: relative;" >
                <input class="nomargin" type="text" @blur="checkUsername()" @input="checkUsername()" v-model="user.username" placeholder="stima">
                <p v-if="busy" style="position: absolute;text-align: right; right: 15px; top: 10px; color: red;">Busy</p>
              </div>
            </Block>

            <!-- name -->
            <Block title="Name">
              <input class="nomargin" type="text" v-model="user.name">
            </Block>

            <Block title="Settings">
                <div class="row">
                  <Switch style="margin-right: 22px;" title="User can login into platform" v-model="user.active">Active</Switch>
                  <Switch style="margin-right: 22px;" title="User email is verified" v-model="user.verified">Verified</Switch>
                  <Switch v-model="user.kyc" title="User is aprooved (Know Your Customer)">KYC</Switch>              
                </div>
            </Block>
            
          </div>
          
          <!-- private -->
          <div v-else>
            
            <!-- name -->
            <Block title="Name">
              <input class="nomargin" type="text" v-model="user.name">
            </Block>

            <!-- username -->
            <Block title="Username">
              <div class="column" style="position: relative;" >
                <input class="nomargin" type="text" @blur="checkUsername()" @input="checkUsername()" v-model="user.username" placeholder="stima">
                <p v-if="busy" style="position: absolute;text-align: right; right: 15px; top: 10px; color: red;">Busy</p>
              </div>
            </Block>

            <!-- settings -->
            <!-- <Block title="Settings">
                <div class="row">
                  <Switch style="margin-right: 22px;" title="User can login into platform" v-model="user.api">API</Switch>
                </div>

                <Block v-if="user.api" title="Access">                
                  <div class="row">
                    <Switch style="margin-right: 22px;" title="User email is verified" v-model="user.createassets">Create assets</Switch>
                    <Switch v-model="user.pushprices" title="User is aprooved (Know Your Customer)">Push prices</Switch>
                  </div>
                </Block>
            </Block> -->

          </div>

            <!-- errors -->
            <Block v-if="errors.length" >
              <p class="bad" v-for="(item, index) in errors" :key="index" style="color: var(--bad); margin: 4px 0 ;" >• {{ item }}</p>
            </Block>
          
            <!-- buttons -->
            <hr>            
            <div class="column">
              <p class="button" @click="create()">Create</p>
              <br>
            </div>
            


      </div>


    <!-- userDone -->   
    <Popup v-model="showDone" done style="z-index: 1000000;">
        <!-- <template #icon><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45 17.5L41.475 13.975L25.625 29.825L29.15 33.35L45 17.5ZM55.6 13.975L29.15 40.425L18.7 30L15.175 33.525L29.15 47.5L59.15 17.5L55.6 13.975ZM1.025 33.525L15 47.5L18.525 43.975L4.575 30L1.025 33.525Z" fill="#2CE833"/></svg></template> -->
        <template #title>Done!</template>
        <template #line>Account #{{ user.id }} has been created! Now you can find it in accounts list.</template>        
          <div class="row center">
            <p class="button" @click="done">OK</p>
          </div>
    </Popup>


    
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({  
  mounted() {          
    if (this.dev) this.inits()
  },
  data() {
    return {    
      id: undefined,        
      showDone: false,
      errors:[],      
      user:{
        id: undefined,
        active: true,
        api: false,
        verified: true,
        kyc: true,
        provider: 0,
        email: undefined,
        name: undefined,
        pass: '12345',
        geoid: undefined,
      },

      busy: false,
    }
  },
  
  methods: {

    inits(){
      this.user = {        
        active: true,
        api: false,
        verified: true,
        kyc: true,
        provider: 2,
        email: `${Math.random()}@gmail.com`,
        name: 'James Test',
        pass: Math.random(),
        geoid: 5128581,
      }
    },

   
    // create user
    /* eslint-disable */ 
    async create(){
      this.errors = []

      if (this.user.provider != 3 && !this.user.email) this.errors.push('Email is empty')              
      if (this.user.provider == 2){
        if (!this.user.pass || this.user.pass.length < 8) this.errors.push('Password less than 8 symbols')
      }
      
      if (this.errors.length) return
      
      // console.log(this.user)
      // return
      let res = await this.api.send('accounts/create',this.user)
      console.log('ress',res)
      if (res.error){
        this.errors.push(`${res.error.code}: ${res.error.id} ${res.error.comment}`)
        return
      }

      console.log("new acccount", res.resp)

      //user
      this.user = res.resp
      this.showDone = true
      this.done()
    },

    done(){
      this.showDone = false;
      this.$emit('update', this.user)
    },

    async checkUsername(){
      if (!this.user.username) {
        this.busy = false
        return
      }
      let res = await this.api.send('accounts/username',{name: this.user.username})
      if (res.error){
        this.busy = true
        return
      }

      this.busy = false
    },

    
  },
});

</script>
<style scoped>

</style>