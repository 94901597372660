<template>
  
    
 
    <div class="column left" style="width: 100%;">

        <!-- provider -->
        <Block title="Language" sub="How user have to login with" must>
          <div class="row">
              <p class="tab" :class="{active: item.lang == i}" @click="item.lang = i;update('lang', item.lang)" v-for="(i, index) in ['en','it','ru']" :key="index">{{ i }}</p>
          </div>
        </Block>


            <!-- title -->
            <Block title="Title" must>
              <input @input="update('title', item.title)" class="nomargin" type="text" placeholder="Bitcoin goes up" v-model="item.title">
            </Block>
            
            <!-- line -->
            <Block title="Line" must>
              <input @input="update('line', item.line)" class="nomargin" type="text" placeholder="Bitcoin goes up" v-model="item.line">
            </Block>
            
            <!-- line -->
            <Block title="Stima comment">
              <input @input="update('stima', item.stima)" class="nomargin" type="text" placeholder="Bitcoin goes up" v-model="item.stima">
            </Block>
            
            <!-- text -->
            <Block title="Text" must>
              <textarea @input="update('text', item.text)" style="min-height: 100px;" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' v-model="item.text"></textarea>
            </Block>
            
            <!-- tags -->
            <!-- <Block title="Tags">
              <input class="nomargin" type="text" placeholder="btc, eth..." v-model="pre.tags">
            </Block> -->

            <!-- image -->
            <Block title="Image" must>
              <Image nopreview v-upload.jpg.png="upload" v-if="item.image" :src="item.image" />
              <p class="tag" v-upload.jpg.png="upload">Upload</p>
            </Block>
            
                  
            <Block title="Settings">
                <div class="row">
                  <Switch @update="update('active', item.active || false)" style="margin-right: 22px;" title="User can login into platform" v-model="item.active">Active</Switch>
                  <Switch @update="update('pin', item.pin || false)" style="margin-right: 22px;" title="User email is verified" v-model="item.pin">Pin</Switch>
                  <!-- <Switch v-model="item.kyc" title="User is aprooved (Know Your Customer)">KYC</Switch>               -->
                </div>
            </Block>
           <br>

      </div>


    <!-- userDone -->   
    <Popup v-model="showDone" done style="z-index: 1000000;">
        <!-- <template #icon><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45 17.5L41.475 13.975L25.625 29.825L29.15 33.35L45 17.5ZM55.6 13.975L29.15 40.425L18.7 30L15.175 33.525L29.15 47.5L59.15 17.5L55.6 13.975ZM1.025 33.525L15 47.5L18.525 43.975L4.575 30L1.025 33.525Z" fill="#2CE833"/></svg></template> -->
        <template #title>Done!</template>
        <template #line>News has been created! Now you can find it in news page.</template>        
          <div class="row center">
            <p class="button" @click="done">OK</p>
          </div>
    </Popup>


    
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({  
  data() {
    return {
      item: this.news,
    }
  },

  props:{
    news: {}
  },
  
  methods: {


    async upload(v){
      let file = v[0]
      console.log(file)       
      let body = await this.tools.readFileAsBytes(file)
      let link = await this.uploadImage(body)      
      if (!link) return 
      
      
      let res = this.update('image',link)
      if (res.error){
        console.error(res.error)
        return
      }
      this.item.image = link
      
    },
    
    async uploadImage(body){
      if (!body) return
      let res = await this.api.upload.image(body)
      if (res.error){
        console.error(res)
        return
      }
      console.log(res)
      return res.resp.link      
    },

    async update(k,v){
      let id = this.item.id
      if (!id) {
        console.error("edit news nil id")
        return
      }

      let res = this.api.send('news/update', {id,k,v})
      if (res.error){
        console.error(res.error)
        return
      }
    },

    done(){      
      this.$emit('update', true)
    },


    
  },
});

</script>
<style scoped>

</style>