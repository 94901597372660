<template>
  
        
  <div class="column pointer click" style="position: relative;margin: 10px 30px 10px 0;">
        <img @click="preview = true" :src="src" :style="{maxWidth: width, maxHeight: height}" style="border-radius: 12px; ">
        <span v-if="close" class="closeIcon pointer click" @click="remove"></span>    
        <p class="mini mt2">{{ sizetitle }}</p>    
    </div>          


    <Transition name="bounce" v-if="!nopreview">
        <Teleport to="body">
            <img v-if="preview" @click="preview = false" class="preview pointer click" :src="original || src">
        </Teleport>
    </Transition>
    
    
</template>
<script>

/*

    <Image close @close="delete" :src="image"/>
 
*/

export default {
    data: function () {return {
        preview: false,        
        res: {}
    }},
    props:{
        modelValue: Boolean,
        close: Boolean,
        width:{type:String, default:'150px'},
        height:{type:String, default:'150px'},
        src: String,          
        original: String, 
        nopreview: Boolean         
    },
    
    async mounted() {
        await this.size()
    },

    computed: {
        sizetitle(){            
            if (!this.src) return ''            
            return `${this.res.width} × ${this.res.height}`
        },
    },
    
    methods: {
        async size(){
            this.res = await this.tools.imageSize(this.src)
        },

        remove(){
            this.$emit('close', true);
        }
    },
}
</script>

<style scoped>
.preview{
    
    transition: all 0.5s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-width: 80vw;
    max-height: 80vh;
    z-index: 1000;
    border-radius: 20px;
}
  .closeIcon{
    width: 22px;
height: 22px;
background: #222222;
position: absolute;
top: -10px;
right: -10px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 25px;
  }

  .closeIcon:hover{
    background: #a74c4c;
  }
  .closeIcon::after{
    content: '×';
    color: var(--g5);
    font-size: 13px;
font-weight: 600;
margin-top: -1px;
  }

  .closeIcon:hover::after{
    color: var(--g9);
  }
</style>

