<template>
    <div class="column left columnPadding" :class="[{full:full, flex:flex, border: border, over: over, fit: fit}, over && dropclass ? dropclass : '']" :style="{padding: padding ||'', minWidth:width || '', width:width || ''}" >
                  
                  <!-- controls -->
                  <div v-if="controls" class="controls" :class="{fit: fit, full:full}" :style="{padding:cpadding}">
                        <div class="row ch">
                          <!-- title -->
                            <p v-if="title" style="margin-right: 8px;" :style="{color: color || ''}" class="title">{{title}}</p>
                            <!-- plus -->
                            <div v-if="plus" class="pointer click" style="margin-right: 8px; margin-top: 8px;" :title="plusTitle" @click="onPlus()">
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389956 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.997 6.61396 17.0479 4.32651 15.3607 2.63933C13.6735 0.952144 11.386 0.00297696 9 0ZM12.4615 9.69231H9.69231V12.4615C9.69231 12.6451 9.61937 12.8212 9.48954 12.9511C9.3597 13.0809 9.18361 13.1538 9 13.1538C8.81639 13.1538 8.6403 13.0809 8.51047 12.9511C8.38063 12.8212 8.30769 12.6451 8.30769 12.4615V9.69231H5.53846C5.35485 9.69231 5.17876 9.61937 5.04893 9.48953C4.9191 9.3597 4.84616 9.18361 4.84616 9C4.84616 8.81639 4.9191 8.6403 5.04893 8.51046C5.17876 8.38063 5.35485 8.30769 5.53846 8.30769H8.30769V5.53846C8.30769 5.35485 8.38063 5.17876 8.51047 5.04893C8.6403 4.91909 8.81639 4.84615 9 4.84615C9.18361 4.84615 9.3597 4.91909 9.48954 5.04893C9.61937 5.17876 9.69231 5.35485 9.69231 5.53846V8.30769H12.4615C12.6452 8.30769 12.8212 8.38063 12.9511 8.51046C13.0809 8.6403 13.1538 8.81639 13.1538 9C13.1538 9.18361 13.0809 9.3597 12.9511 9.48953C12.8212 9.61937 12.6452 9.69231 12.4615 9.69231Z" :fill="plusColor"/></svg>
                            </div>
                            <!-- menu left/after title -->
                            <slot name="left"></slot>
                        </div>
                        <!-- menu right -->
                        <div class="row ch">
                            <slot name="right"></slot>    
                        </div>
                  </div>

                  <!-- fixed -->
                  <Scroll v-if="width" y :class="{fit: fit, full:full}">  
                    <div class="column" :class="{row: row, wrap: row,fit: fit}" :style="{flex: `0 0 ${width}`}" style="align-items: flex-start;">
                       <slot></slot>
                    </div>
                  </Scroll>

                  <!-- 100% -->
                  <Scroll v-else y :class="{fit: fit, full:full}">  
                    <div class="column" :class="{row: row, wrap: row,fit: fit}" style="flex:1; align-items: flex-start">
                      <slot></slot>
                    </div>
                  </Scroll>
    </div>

    <!-- 
        <Column border controls plus plusColor="black" @plus="plusClick" width="300px" title="Column" padding="0px 10px 0px 10px">          
          <template #left></template>
          <template #right></template>
          Content...
        </Column>
     -->
</template>
<script>
export default {
    data: function () {return {}},
    props:{
        row: Boolean,
        full: Boolean,
        fit: Boolean,
        border: Boolean,
        padding: String,
        controls: Boolean, //remove title etc
        flex:Boolean, //width 100%
        table: Boolean,
        width: String, //fixed if has
        title: String,
        color:String,

        plus: Boolean,
        plusColor:{type: String, default:'var(--text)'},
        plusTitle:{type: String, default:'Add'},

        notop:Boolean,
        // cpadding:{type: String, default:"15px 30px 15px 0px"},
        cpadding:{type: String, default:"0px"},
        
        
        over: Boolean, //on dragover
        dropclass: String, //custom dragover class
      },
      computed: {},
      methods:{
        onPlus(){
          this.$emit('plus', true)
        }
      },
}
</script>

<style scoped>
.fit{
  width: -moz-fit-content; 
  width: fit-content;
}
.title{  
  font-family: var(--titlefonts);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: var(--text);  
  letter-spacing:-0.02em;
  user-select: none;
}

.hovers:hover{
  opacity: .6;
}

.controls{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 0.5px solid var(--g1); */
  width: 100%;
}

.columnPadding{
  float:left;
  box-sizing: border-box; 
  position: relative;
  /* padding:0 0px 0 20px; */
}

.border{
  border-right: 0.5px solid var(--g1);
  border-radius: 0;
}

.over{
  background: rgb(255, 245, 210);
  outline: 5px dotted rgb(255, 205, 26);
}
.flex{
  flex:1; 
  align-items: flex-start
}

.full{
  
  width: 100%;
  
}

</style>