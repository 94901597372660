<template>
    
    
        <Teleport to="body">
            <Transition name="bounce">
            <div v-if="modelValue" class="main">

                
        
            <div ref="target" class="pop center" :style="{position: scroll ? 'relative':'fixed'}" :class="{nopadding}">            
                
                <!-- close -->
                <svg style="position: absolute; top: 10px;right: 10px; cursor: default;" @click="close()" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle class="pointer" cx="6" cy="6" r="6" fill="var(--g1)"/></svg>
                
                
                <div class="column center">  

                    <!-- icons -->
                    <div class="column topicon" v-if="plus || user || info || love || fatal || chat || search || flash || done || settings">
                        <svg v-if="!loading &&plus"    width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z" fill="#4CAF50"/><path d="M21 14H27V34H21V14Z" fill="white"/><path d="M14 21H34V27H14V21Z" fill="white"/></svg>
                        <svg v-if="!loading &&user"    width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 37L19 31V25H29V31L24 37Z" fill="#FF9800"/><path d="M33 21C34.1046 21 35 20.1046 35 19C35 17.8954 34.1046 17 33 17C31.8954 17 31 17.8954 31 19C31 20.1046 31.8954 21 33 21Z" fill="#FFA726"/><path d="M15 21C16.1046 21 17 20.1046 17 19C17 17.8954 16.1046 17 15 17C13.8954 17 13 17.8954 13 19C13 20.1046 13.8954 21 15 21Z" fill="#FFA726"/><path d="M33 13C33 5.39998 15 7.99998 15 13V20C15 25 19 29 24 29C29 29 33 25 33 20V13Z" fill="#FFB74D"/><path d="M24 4C17.9 4 14 8.9 14 15V17.3L16 19V14L28 10L32 14V19L34 17.3V15C34 11 33 7 28 6L27 4H24Z" fill="#424242"/><path d="M28 20C28.5523 20 29 19.5523 29 19C29 18.4477 28.5523 18 28 18C27.4477 18 27 18.4477 27 19C27 19.5523 27.4477 20 28 20Z" fill="#784719"/><path d="M20 20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18C19.4477 18 19 18.4477 19 19C19 19.5523 19.4477 20 20 20Z" fill="#784719"/><path d="M24 43L19 31L24 32L29 31L24 43Z" fill="white"/><path d="M23 35L22.3 39.5L24 43.5L25.7 39.5L25 35L26 34L24 32L22 34L23 35Z" fill="#D32F2F"/><path d="M29 31L24 43L19 31C19 31 8 33 8 44H40C40 33 29 31 29 31Z" fill="#546E7A"/></svg>
                        <svg v-if="!loading &&info"    width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.2 44.8L3.2 26.8C1.6 25.2 1.6 22.7 3.2 21.1L21.2 3.10002C22.8 1.50002 25.3 1.50002 26.9 3.10002L44.9 21.1C46.5 22.7 46.5 25.2 44.9 26.8L26.9 44.8C25.3 46.4 22.7 46.4 21.2 44.8Z" fill="#FFC107"/><path d="M24 26C25.1046 26 26 25.1046 26 24C26 22.8954 25.1046 22 24 22C22.8954 22 22 22.8954 22 24C22 25.1046 22.8954 26 24 26Z" fill="#37474F"/><path d="M32 26C33.1046 26 34 25.1046 34 24C34 22.8954 33.1046 22 32 22C30.8954 22 30 22.8954 30 24C30 25.1046 30.8954 26 32 26Z" fill="#37474F"/><path d="M16 26C17.1046 26 18 25.1046 18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26Z" fill="#37474F"/></svg>
                        <svg v-if="!loading &&love"    width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34 9C29.8 9 26.1 11.1 24 14.4C21.9 11.1 18.2 9 14 9C7.4 9 2 14.4 2 21C2 32.9 24 45 24 45C24 45 46 33 46 21C46 14.4 40.6 9 34 9Z" fill="#F44336"/></svg>
                        <svg v-if="!loading &&fatal"   width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.2 44.8L3.2 26.8C1.6 25.2 1.6 22.7 3.2 21.1L21.2 3.10002C22.8 1.50002 25.3 1.50002 26.9 3.10002L44.9 21.1C46.5 22.7 46.5 25.2 44.9 26.8L26.9 44.8C25.3 46.4 22.7 46.4 21.2 44.8Z" fill="#F44336"/><path d="M21.5996 32.7C21.5996 32.4 21.6996 32.1 21.7996 31.8C21.8996 31.5 22.0996 31.3 22.2996 31.1C22.4996 30.9 22.7996 30.7 23.0996 30.6C23.3996 30.5 23.6996 30.4 24.0996 30.4C24.4996 30.4 24.7996 30.5 25.0996 30.6C25.3996 30.7 25.6996 30.9 25.8996 31.1C26.0996 31.3 26.2996 31.5 26.3996 31.8C26.4996 32.1 26.5996 32.4 26.5996 32.7C26.5996 33 26.4996 33.3 26.3996 33.6C26.2996 33.9 26.0996 34.1 25.8996 34.3C25.6996 34.5 25.3996 34.7 25.0996 34.8C24.7996 34.9 24.4996 35 24.0996 35C23.6996 35 23.3996 34.9 23.0996 34.8C22.7996 34.7 22.5996 34.5 22.2996 34.3C22.0996 34.1 21.8996 33.9 21.7996 33.6C21.6996 33.3 21.5996 33.1 21.5996 32.7ZM25.7996 28.1H22.1996L21.6996 13H26.2996L25.7996 28.1Z" fill="white"/></svg>
                        <svg v-if="!loading &&chat"    width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37 39H11L5 45V11C5 7.7 7.7 5 11 5H37C40.3 5 43 7.7 43 11V33C43 36.3 40.3 39 37 39Z" fill="#8BC34A"/></svg>
                        <svg v-if="!loading &&search"  width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.1748 31.99L32.0028 29.163L44.0218 41.182L41.1938 44.009L29.1748 31.99Z" fill="#616161"/><path d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z" fill="#616161"/><path d="M32.4502 35.34L35.2772 32.512L43.9732 41.208L41.1452 44.036L32.4502 35.34Z" fill="#37474F"/><path d="M20 33C27.1797 33 33 27.1797 33 20C33 12.8203 27.1797 7 20 7C12.8203 7 7 12.8203 7 20C7 27.1797 12.8203 33 20 33Z" fill="#64B5F6"/><path d="M26.9003 14.2C25.2003 12.2 22.7003 11 20.0003 11C17.3003 11 14.8003 12.2 13.1003 14.2C12.7003 14.6 12.8003 15.3 13.2003 15.6C13.6003 16 14.3003 15.9 14.6003 15.5C16.0003 13.9 17.9003 13 20.0003 13C22.1003 13 24.0003 13.9 25.4003 15.5C25.6003 15.7 25.9003 15.9 26.2003 15.9C26.4003 15.9 26.7003 15.8 26.8003 15.7C27.2003 15.3 27.2003 14.6 26.9003 14.2Z" fill="#BBDEFB"/></svg>
                        <svg v-if="!loading &&flash"   width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33 22H23.6L30 5H19L13 26H21.6L17 45L33 22Z" fill="#FFC107"/></svg>
                        <svg v-if="!loading &&done"    width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z" fill="#4CAF50"/><path d="M34.5996 14.6L20.9996 28.2L15.3996 22.6L12.5996 25.4L20.9996 33.8L37.3996 17.4L34.5996 14.6Z" fill="#CCFF90"/></svg>
                        <svg v-if="!loading &&settings" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.4002 24.6L25.8002 22.4L21.9002 11.2L18.1002 12.5L22.0002 23.6L14.2002 32.6L17.2002 35.2L25.0002 26.2L36.6002 28.4L37.4002 24.6Z" fill="#3A8152"/><path d="M24 19C21.2 19 19 21.2 19 24C19 26.8 21.2 29 24 29C26.8 29 29 26.8 29 24C29 21.2 26.8 19 24 19ZM24 26C22.9 26 22 25.1 22 24C22 22.9 22.9 22 24 22C25.1 22 26 22.9 26 24C26 25.1 25.1 26 24 26Z" fill="#46B966"/><path d="M40.7001 27C40.9001 26 41.0001 25 41.0001 24C41.0001 23 40.9001 22 40.7001 21L44.0001 18.6C44.4001 18.3 44.6001 17.7 44.3001 17.2L40.0001 9.8C39.7001 9.3 39.2001 9.1 38.7001 9.4L35.0001 11C33.5001 9.7 31.7001 8.7 29.8001 8L29.4001 3.9C29.3001 3.4 28.9001 3 28.4001 3H19.8001C19.3001 3 18.8001 3.4 18.8001 3.9L18.2001 8C16.3001 8.7 14.5001 9.7 13.0001 11L9.30011 9.3C8.80011 9.1 8.20011 9.3 8.00011 9.8L3.70011 17.2C3.40011 17.7 3.60011 18.3 4.00011 18.6L7.30011 21C7.10011 22 7.00011 23 7.00011 24C7.00011 25 7.10011 26 7.30011 27L4.00011 29.4C3.60011 29.7 3.40011 30.3 3.70011 30.8L8.00011 38.2C8.30011 38.7 8.80011 38.9 9.30011 38.6L13.0001 37C14.5001 38.3 16.3001 39.3 18.2001 40L18.6001 44.1C18.7001 44.6 19.1001 45 19.6001 45H28.2001C28.7001 45 29.2001 44.6 29.2001 44.1L29.6001 40C31.5001 39.3 33.3001 38.3 34.8001 37L38.5001 38.7C39.0001 38.9 39.6001 38.7 39.8001 38.3L44.1001 30.9C44.4001 30.4 44.2001 29.8 43.8001 29.5L40.7001 27ZM24.0001 35C17.9001 35 13.0001 30.1 13.0001 24C13.0001 17.9 17.9001 13 24.0001 13C30.1001 13 35.0001 17.9 35.0001 24C35.0001 30.1 30.1001 35 24.0001 35Z" fill="#46B966"/></svg>
                        
                        <!-- loader -->
                        <Loader v-if="loading" width="48px" color="#A3568F" borders="4px solid #0000"/>
                    </div>

                

                    <div class="column center" :style="{margin: nopadding ? '' : '0px 0 10px'}">
                        
                        <!-- icon -->
                        <div v-if="!loading && $slots.icon" class="icon"><slot name="icon"></slot></div>                    
                        <Loader v-if="$slots.icon && loading" width="48px" color="#A3568F" borders="4px solid #0000"/>

                        <!-- title -->
                        <p v-if="up" class="up">{{ up }}</p>
                        <p v-if="$slots.title || title" class="title">{{ title }}<slot name="title"></slot></p>
                        <!-- line -->
                        <p v-if="$slots.line || line" class="line">{{ line }}<slot name="line"></slot></p>
                        <!-- desc -->
                        <p v-if="$slots.desc || desc" class="desc w300">{{ desc }}<slot name="desc"></slot></p>                
                    </div>              
                    <div class="body"><slot></slot></div>            
                </div>            
            </div>
        
    </div>

    </Transition>
</Teleport>
    
<!--         
    
    <Popup v-model="db.settings.login">
        <template #icon><svg/></svg></template>
        <template #title>{{$t('title.welcome')}}</template>
        <template #line>{{$t('line.welcome')}}</template>
        <template #desc>{{$t('desc.welcome') }}</template>  
        <Login @done="db.settings.login = false"/>      
    </Popup>        

-->
     
</template>
<script>
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
export default {
    setup(props, { emit }) {
        const target = ref(null)
        onClickOutside(target, (event) => emit('update:modelValue', false))
        return { target}
    },
    data: function () {
        return {}},
    props:{
        modelValue: Boolean,
        scroll: Boolean,
        loading: Boolean,

        title:String,
        up:String,
        line:String,
        desc:String,
        padding:{type:String, default:'10px 20px 10px'},
        nopadding: Boolean,


        plus: Boolean,
        user: Boolean,
        love: Boolean,
        info: Boolean,
        fatal: Boolean,
        chat: Boolean,
        search: Boolean,
        flash: Boolean,
        done: Boolean,
        settings: Boolean,
    },
    methods: {
        close(){
            this.$emit('update:modelValue', false);
        },
    },

    filters: {
    checkEmpty(value) {
      return typeof value !== "number" ? 0 : value;
    }
  },
}
</script>

<style scoped>

.main{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    position: fixed; 
    z-index: 1000; 
    width: 100vw; 
    height: 100vh; 
    top: 0;
    left: 0;
    padding: none,    
}
   .pop {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: var(--popupBack);       
        border-radius: var(--popupBorder);    
        position: absolute;  
        z-index: 1000;        
        /* box-shadow: 0px 100px 426px rgba(0, 0, 0, 0.18), 0px 46.233px 196.953px rgba(0, 0, 0, 0.0784783), 0px 26.4535px 112.692px rgba(0, 0, 0, 0.0379435), 0px 16.0571px 68.4031px rgba(0, 0, 0, 0.0222537), 0px 9.67509px 41.2159px rgba(0, 0, 0, 0.0197508), 0px 5.38772px 22.9517px rgba(0, 0, 0, 0.0237333), 0px 2.31722px 9.87136px rgba(0, 0, 0, 0.0270284); */
        box-shadow: var(--popupShadow);
        max-height: 90vh;
        overflow-y: scroll;
   }

   .icon{
    margin: 10px 0 10px;    
   }

   .topicon{
    /* position: absolute; */
    margin-top: 20px;
    /* top:10px; */
    z-index: 1000;
   }
   .title{
        font-family: var(--titleFonts);
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 60%;
        text-align: center;
        letter-spacing: -0.0em;
        color: var(--text);        
        margin-bottom: 12px;
   }

   .line{
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        text-align: center;
        color: var(--g3);
        margin-top: 0px;
        letter-spacing: 0.02em;
        
   }

   .desc{
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 140%;
        text-align: center;
        color: var(--g3);
        margin-top: 4px;
        letter-spacing: 0.02em;
   }
   .up{
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 140%;
        text-align: center;
        color: var(--g3);
        /* margin-top: 4px; */
        letter-spacing: 0.02em;
        margin-bottom: 10px;
   }
   
   .headspace{
    display: flex;
       flex-direction: column;
       justify-content: center;
    width:100%; 
    padding: 10px 0;
    user-select: none;
   }
   .nopadding{
    padding:0
   }
   .wrapper{
       position: relative;
       display: flex;
       flex-direction: column;
       /* justify-content: space-between; */
       align-items: flex-start;
       height: 100%;
       width: 100%;
   }


    .body{
        position: relative;
       display: flex;
       flex-direction: column;
       width: 100%;
       padding: 0px 0px;
   }

    .close{
      cursor: default;
      transition: all 0.1s ease;
    }
    
    .close:hover{
      fill-opacity: 0.16;
    }

    .scroll{
        overflow-y: scroll;
    }

</style>