<template>
    
    <transition name="fade">
      <div @click.prevent="click" class="row ch" style="margin: 0;padding: 0;">
        <div class="switcher" :class="{active : modelValue || value, line: line, small:small}" :style="{background: (modelValue || value) && color ? color : ''}">
            <div class="ball" :class="{active : modelValue || value, smallball:small}"></div>
        </div>
        <p class="ml2 pointer click" :class="{bold:bold}"><slot></slot></p>
      </div>
     </transition>
     
</template>
<script>
/* 
  <Switch v-model="item.readonly" title="Compact" @update="update()"></Switch>
 */
export default {
    data: function () {return {
      
    }},
    emits: ["update:modelValue"],
    props:{
        color:String,
        line: Boolean,
        small:Boolean,
        bold:Boolean,
        modelValue: Boolean,
        value: Boolean,
    },
    methods: {
        click(){            
            this.$emit('update:modelValue', !this.modelValue )
            this.$emit('update', !this.value);
        }
    },
}
</script>

<style scoped>

/* desktop */
@media (min-width: 800px){
    .switcher{
      cursor: pointer;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    min-width: 36px;
    width: 38px;
    height: 20px;
    border-radius: 200px;
    background: var(--switchOff);
    padding: 0 1px;
  }
  .switcher.active{
    justify-content: flex-end;
    background: var(--switchOn);
  }
  .switcher.active .ball{
    box-shadow: -2px 0px 3px rgba(0, 0, 0, 0.1);
  }
  .ball{
    width: 20px;
    height: 20px;
    border-radius: 200px;
    background: var(--switchBallOff);  
    box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.1);
  }

  .ball.active{
    background: var(--switchBallOn);  
  }

  .line{
    background: none;
    border: 1px solid var(--g15);
  }

   .small{
    cursor: pointer;
    min-width: 8px;
    width: 26px;
    height: 15px;
    border-radius: 200px;
    margin:0;
    
  }

  .smallball{
   width: 13px;
  height: 13px;
  border-radius: 200px;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.2);
  }


  }
  
  
  /* mobile */
  @media (max-width: 800px) {
    .switcher{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    width: calc( 52 / 375 * 100vw);
    height: calc( (52 / 375 * 100vw) * (30 / 52) );
    border-radius: 200px;
    background: var(--g1);
    padding: 0 calc(2/375 * 100vw);
  }
  .switcher.active{
    justify-content: flex-end;
    background: var(--good);
  }
  .switcher.active .ball{
    box-shadow: -2px 0px 3px rgba(0, 0, 0, 0.1);
  }
  .ball{
    width: calc(26 / 375 * 100vw);
    height: calc(26 / 375 * 100vw);
    border-radius: 200px;
    background: white;  
    box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.1);
  }
  }

  
    
</style>