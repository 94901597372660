<template>
   <div class="column" :class="{mb4 : !hr}">
        <div class="row ch">
        <h2 :class="{must:must}" ><slot></slot></h2>
        <div v-if="plus" class="plus click" style="margin-top: 4px;" @click.prevent="click">+</div>           
        <slot name="more"></slot>
    </div>
        <p v-if="$slots.desc" class="mini w250"><slot name="desc"></slot> </p>
        <hr v-if="hr">
        
   </div>
</template>
<script>

/*

    <Title @plus="update">Attributes<template #desc>Desc about</template></Title>
 
*/

export default {
    data: function () {return {}},
    props:{
        plus: Boolean,
        hr:Boolean,
        must:Boolean,
        // modelValue: Boolean
    },
    // emits: ["update:modelValue"],
    methods: {
        click(){            
            // this.$emit('update:modelValue', !this.modelValue )
            this.$emit('plus', true);
        }
    },
}
</script>

<style scoped>
  
</style>

