<template>
     <div class="column inputBox" style="position: relative;">
      
          <input  
              style="margin: 0;transition: none;"              
              ref="input"
              :class="{noBottomBorder: items.length}"                           
              type="text"
              v-model="searchText"
              :placeholder="placeholder"
              @keydown.down="focusHandler" 
              @keydown.up="focusHandler" 
              @keydown.enter.prevent="focusHandler"
              @input="search">          
              
          <!-- clear -->
          <div class="clearicon pointer click" v-if="searchText" @click="clear()"><span>×</span></div>
          
          <!-- list -->
            <div class="autocomplete" v-out="out" :style="{height, width: `${$refs.input.clientWidth}px`, marginTop: `${$refs.input.clientHeight}px`}" v-if="items.length">
              <div class="itembox row ch" @click="current = item; update()" v-for="(item, index) in items" :key="index" :class="{ active: index === focus }">                
                  <p class="item">{{ item.title }}</p>                
              </div>
            </div>          
        </div>

        <!-- 

          <Brand :asset="1" v-model="attr.brand" placeholder="Brand" @name="brandname = $event" class="w300" height="300px"/>
         -->
</template>
<script>
export default {
   
    data: function () {return {
      current:{},          
      focus: null,
      searchText: this.modelValue,     
      items: [],
    }},
    
    props: {    
      asset: Number,
      modelValue: String,
      placeholder: String,
      height: {type:String, default:'300px'},
    },
    watch: {
      modelValue(val){
        this.searchText = val
      },
    },

    methods: {
      focusHandler(event) {
        switch (event.keyCode) {
          case 13:
            if (!this.focus) {
              this.current = this.items[0]
              this.update()
              return
            }             
              this.current = this.items[this.focus]
              this.update()            
              return
          case 38:
            if (this.focus === null) {
              this.focus = 0;
            } else if (this.focus > 0) {
              this.focus--;
            }
            break;
          case 40:
            if (this.focus === null) {
              this.focus = 0;
            } else if (this.focus < this.items.length - 1) {
              this.focus++;
            }
            break;
        }
      },

      out(){
        console.log('outside')
        this.items=[]
        // this.clear()
      },
      clear(){
        this.current = {}
        this.searchText = undefined
        this.$emit('update:modelValue', undefined)  
        this.$emit('id', undefined)
        this.items = []
      },
      
       update() {
        this.$emit('update:modelValue', this.current.title)          
        this.$emit('id', Number( this.current.id))
        this.$emit('name', this.current.title)
        this.items = []
      },


      async search(){
        let res = await this.api.send('dict/search', {fields:['id','title'], parent: this.asset, name:'brands', limit:30, offset:0, like: `${this.searchText}%`})        
        console.log(res)
        if (res.error){
          console.log(res.error)        
          return
        }
        this.items = res.resp || []
        /* {
          "id": 1,
          "title": "Rolex",		    
        }, */
    },


    },
}
</script>

<style scoped>
.item {
  font-size: 15px;
  margin-top: -1px;
  margin-left: 2px;
}

.inputBox {
  margin: 0px 0 12px;
  margin: 0px 0 0px;
}

/* input */
.autocomplete {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--back);
  position: absolute;
  z-index: 1000;
  /* margin-top: 30px; */
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px dashed var(--g1);
}

.itembox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  /* background-color: var(--back); */
  background: var(--input);
  padding: 2px 0px 2px 6px;
}

.itembox:hover, .itembox.active {
  background-color: var(--g1);
}

/* .autocomplete div p {
  padding: 2px 8px;
  text-align: left;
} */

.noBottomBorder {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.text {
  width: 100%;
  position: relative;
  padding: 0px 0;
  margin: 0;
}

.input {
  padding: 5px 12px 8px;
  border-radius: 8px;
  background: var(--g05);
}

.clear {
  background: none;
}

.inputIcon {
  padding-left: 50px;
}

.icon {
  height: 20px;
  width: auto;
  position: absolute;
  top: 11px;
  left: 12px;
  border-radius: 4px;
}

.clearicon {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  position: absolute;
  top: 11px;
  right: 7px;
  border-radius: 100px;
  background-color: var(--g1);

}

.clearicon span {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  color: var(--g3);
  margin-top: -1px;
}

.iconpadding {
  padding-left: 30px;
}

div.focus {
  border: 1px solid blue;
}

</style>

