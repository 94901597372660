<template>
    
    <div class="footer">
        <div class="container">
            <div class="cols">
                <div class="logo">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 15C0 6.71573 6.71573 0 15 0V0C23.2843 0 30 6.71573 30 15V15C30 23.2843 23.2843 30 15 30V30C6.71573 30 0 23.2843 0 15V15Z" fill="var(--primary)"/>
                        <path d="M22.9688 17.5926C22.9688 18.4262 22.8388 19.2277 22.5788 19.9972C22.3189 20.7667 21.9371 21.448 21.4334 22.0411C20.946 22.6183 20.3449 23.0831 19.6301 23.4358C18.9152 23.7885 18.1029 23.9648 17.1931 23.9648C16.3807 23.9648 15.609 23.8045 14.8779 23.4839C14.1631 23.1473 13.6107 22.6744 13.2208 22.0652H13.172V23.6282H9.49219V5.44922H13.4889V12.9276H13.5376C13.8788 12.5268 14.358 12.1581 14.9754 11.8215C15.5928 11.4848 16.3483 11.3165 17.2418 11.3165C18.1191 11.3165 18.9071 11.4848 19.6057 11.8215C20.3206 12.1581 20.9217 12.615 21.4091 13.1921C21.9127 13.7692 22.2945 14.4425 22.5545 15.212C22.8307 15.9655 22.9688 16.759 22.9688 17.5926ZM19.1183 17.5926C19.1183 17.2078 19.0533 16.8311 18.9233 16.4624C18.8096 16.0937 18.6309 15.7731 18.3872 15.5006C18.1435 15.212 17.8429 14.9796 17.4855 14.8032C17.1281 14.6269 16.7138 14.5387 16.2427 14.5387C15.7877 14.5387 15.3816 14.6269 15.0242 14.8032C14.6667 14.9796 14.358 15.212 14.0981 15.5006C13.8544 15.7891 13.6594 16.1177 13.5132 16.4865C13.3833 16.8552 13.3183 17.2319 13.3183 17.6166C13.3183 18.0014 13.3833 18.3781 13.5132 18.7468C13.6594 19.1155 13.8544 19.4441 14.0981 19.7327C14.358 20.0213 14.6667 20.2537 15.0242 20.43C15.3816 20.6064 15.7877 20.6946 16.2427 20.6946C16.7138 20.6946 17.1281 20.6064 17.4855 20.43C17.8429 20.2537 18.1435 20.0213 18.3872 19.7327C18.6309 19.4441 18.8096 19.1155 18.9233 18.7468C19.0533 18.3621 19.1183 17.9773 19.1183 17.5926Z" fill="white"/>
                    </svg>
                    <div class="column">
                        <p><b>Bout Media</b></p>
                        <p>2023</p>
                    </div>
                </div>
            </div>

            <div class="cols">
                <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{t(item.id)}}</router-link>
            </div>

            <div class="cols">
                <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{t(item.id)}}</router-link>
            </div>

            <div class="cols">
                <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{t(item.id)}}</router-link>
            </div>

            <div class="cols">
                <p class="item">Dark mode</p>
                <Mode></Mode>
            </div>
        </div>
    </div>
    
    
</template>
<script>

export default {
    data: function () {return {
        action: [
            { id: 'home', link:'/'},
            { id: 'search', link:'/search'},
            { id: 'people', link:'/people'},
            { id: 'top', link:'/top'},
            { id: 'wait', link:'/wait'},
        ]
    }},
    components:{},
    props:{
        
    },
}
</script>


<style scoped>
.footer{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding: 50px 0 400px;
    background: var(--footer);
    margin-top: 20vh;
}

.container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1200px;
    background: none;

}

.cols{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 50px;
    align-items: flex-start;
    justify-content: flex-start;
}

.logo{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.logo p{
    color:  var(--text);
    text-align: left;
    font-size: 11px;
    line-height: 15px;
    margin-left: 10px;
}


.item{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0em;
    color:  var(--g4);
    text-decoration: none;
    margin: 0 0px 6px;
    white-space: nowrap;
}
.item.router-link-exact-active{
    opacity: .3;
}
</style>

