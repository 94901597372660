<template>
     <div class="row inputBox" style="position: relative;">
      
          <p class="label">{{ curr ? curr.toUpperCase() :'' }} ▾</p>
          
          <select v-model="curr" class="currencySelect" @change="updateCurrency">
            <option v-for="(item, index) in dict.currency" :key="index" :title="item.desc" :value="item.id">{{item.title}}</option>
          </select>

          <input  
              style="margin: 0; transition: none; padding-left: 80px; width: 100%;"
              ref="input"              
              type="number"
              v-model.number="price"
              :placeholder="placeholder || 'Price'"   
              @blur="update"
              @input="update">
                  
      </div>

        <!-- 
          <Price v-model="geoid" currency="usd" placeholder="Location" @update="currency" class="w300"/>
         -->
</template>
<script>
export default {
   
    data: function () {return {
      price: this.value,
      curr: this.currency || 'usd',  
      
    }},

    props: {
      value: Number,
      currency: String,
      modelValue: Number,
      placeholder: String,
    },

    watch: {
      modelValue: {
        handler(val){ this.price = val},
      }
    },

    mounted() {
    //  
    },

    methods: {
      
       update() {
        console.log('price',this.curr, this.price)
        if (isNaN(this.price)) return
        this.$emit('update:modelValue', this.price)          
        this.$emit('currency', this.curr)
      },

      updateCurrency(){
        this.$emit('currency', this.curr)
      },
       

    },
}
</script>

<style scoped>
.label{
  position: absolute;
  left: 14px;
  top: 11px;
  font-family: monospace;
  font-weight: 700;
  font-size: 14px;
  padding-right: 9px;  
  border-right: 0.5px solid var(--g1);
}
.currencySelect{
  position: absolute;
  /* left: 4px; */
  /* border-right: 0.5px solid var(--g1); */
  height: 100%;
  
  border-radius: 0;
  width: 55px;
  width: fit-content;
  padding: 13px;  
  background: none;  
  opacity: 0;
}
.currencySelect:hover{
  
/* background: var(--g05); */
color: var(--g7);
}


.currencySelect::after{
  content: '▾';
}
    .inputBox{
      margin: 0px 0 12px;
      margin: 0px 0 0px;
    }

  .input{
    padding: 5px 12px 8px;      
    border-radius: 8px;
    background: var(--g05);
  }
  
  .clear{
    background:none;
  }

  .inputIcon{
    padding-left: 50px;      
  }

  .icon{
    height: 20px;
    width: auto;
    position: absolute;
    top: 11px;
    left: 12px;
    border-radius: 4px;
  }
  .clearicon{
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    position: absolute;
    top: 11px;
    right: 7px;
    border-radius: 100px;
    background-color: var(--g1);
    
  }
  .clearicon span{
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    color: var(--g3);
    margin-top: -1px;
  }

  .iconpadding{
    padding-left: 30px;
  }

</style>

