<template>

    <div class="column" :class="{big:big, inmargin: !big}" style="position: relative;">
      <div class="row ch">
        <div v-if="done" class="column" style="margin-top: -10px;margin-right: 10px;"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="22" height="22" rx="11" fill="var(--good)"/><path d="M7 11.2L9.54548 14L15 8" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
        <p v-if="title" class="label" :style="{margin: desc ? '0 0 6px' : '0 0 10px'}" :class="{must:must, head:big}">{{ title }}</p>
      </div>
        
        <p v-if="desc" class="desc w200" :style="{margin: '0px 0 8px'}">{{ desc }}</p>  
        
        <div class="line" v-if="hr"></div>      
        <slot></slot>
        <p v-if="error" class="error" :style="{margin: '8px 0 8px'}">{{ error }}</p>  
    </div>
            
    <!-- <Block title="Email" desc="Only gmail is available" must>
        <input type="text">
    </Label>  -->

</template>
<script>
export default {
    data: function () {return {
        
    }},
    props:{
        must:Boolean,
        big:Boolean,
        hr:Boolean,
        done:Boolean,
        title: String,
        desc: String,
        error: String,
    },
   
}
</script>
<style scoped>
.label{
    font-size: 15px;
    font-weight: 600;
    color: var(--g7);   
    line-height: 14px; 
  }

  .nodesc{
    margin: 24px 0 4px;
  }

  .must::after{
    content: '*';
    color: var(--primary);
    margin-left: 2px;
  }
  .desc{
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: var(--g2); 
    /* margin-bottom: 8px; */
  }
  .desc:hover{
    color: var(--g6);     
  }
  .big{
    margin: 12px 0 40px;
  }
  .inmargin{
    margin: 12px 0 12px;
  }
  .head{
    font-size: 18px;
    font-weight: 600;
    color: var(--g7);   
    line-height: 100%; 
  }

  .line{
    border-bottom: 0.5px solid var(--g05);
    margin-bottom: 12px;
  }
  .error{
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    color: rgb(215, 39, 60); 
    margin: 4px 0;
  }
</style>




















