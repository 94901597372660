<template>
   <div class="column" :class="{mb4 : !hr}">
        <div class="row ch">
        <h2 v-if="title" :class="{must:must}">{{ title }}</h2>        
        <slot></slot>
    </div>
        <p v-if="$slots.desc" class="mini w250">{{ desc }}</p>
        <hr v-if="hr">        
   </div>
</template>
<script>

/*

    <T title="Update" desc="Desc">buttons...</T>
 
*/

export default {
    data: function () {return {}},
    props:{   
        title: String,     
        desc: String,     
        hr:Boolean,
        must:Boolean,        
    },        
}
</script>

<style scoped>
  
</style>

