<template>
  
  <box>
    <!-- {{ items }} -->
    <!-- <div v-if="!items.length">
      no api
    </div> -->
    <div>
      <div class="row center">
      <p class="link pointer click" @click="showNewApi ? showNewApi = false : showNewApi = true">+create a new api token</p>
    </div>
      <br>
      <div v-if="showNewApi" class="column left padding" style="background-color: var(--g05); margin-bottom: 24px;">
          <p>Title</p>     
          <input type="text" class="mb3" v-model="token.name" placeholder="James Team Token...">
          
          <p>Limits</p>
          <select class="formselect w200 mb3" v-model="token.limit">
            <option :value="i" v-for="(i, index) in [0,10,30,50,100,200,1000,2000,5000]" :key="index">{{ i == 0 ? 'No limit' : `${i} req/s` }}</option>
          </select>
          
          <div class="row">
            <p class="button primary" style="margin-right: 6px;" @click="createNewToken()">Create</p>
            <p class="button primary" @click="showNewApi = false">Cancel</p>
          </div>          
      </div>

      <div class="column left" v-for="(item, index) in items" :key="index" style="margin-bottom: 50px;">
        
        <h4>{{ item.name }}</h4>
        <p class="micro">{{ tools.ago( item.created) }}</p>
        <br>
        <div class="row pointer click hover" title="click to copy..." @click="copyText(apitoken)" style="padding: 20px 30px; background-color: var(--g05);">
          <p class="mono">{{ item.token }}</p>
        </div>
        
        <br>
        
        

        <table>
          <tr class="bold">
            <td>Category</td>            
            <td>Create</td>
            <td>Get</td>
            <td>Prices</td>            
            <td>Brands</td>
          </tr>
          <tr v-for="(root, index) in dict.root.all" :key="index" class="hover">
            <td > <span class="bold" :style="{color: root.active ? 'var(--primary)':'var(--g3)'}">• </span>{{ root.title }}</td>
            <td class="ccc"><Switch :value="rules[root.id].create" @update="rules[root.id].create ? deleteRule(rules[root.id].create) : addRule(item.id, root.id, 'create')"></Switch></td>
            <td class="ccc"><Switch :value="rules[root.id].get" @update="rules[root.id].get ? deleteRule(rules[root.id].get) : addRule(item.id, root.id, 'get')"></Switch></td>
            <td class="ccc"><Switch :value="rules[root.id].prices" @update="rules[root.id].prices ? deleteRule(rules[root.id].prices) : addRule(item.id, root.id, 'prices')"></Switch></td>
            <td class="ccc"><Switch :value="rules[root.id].brands" @update="rules[root.id].brands ? deleteRule(rules[root.id].brands) : addRule(item.id, root.id, 'brands')"></Switch></td>
            
            
          </tr>
        </table>


      </div>

    </div>


    <!-- new api -->   
     <!-- <teleport to='body'>
      <Popup v-model="showNewApi" padding user style="z-index: 10000000;">
        <template #title>Create a new API token</template>
        <template #line>Create a new token could give an access to account.</template>   
        <div class="padding">
          <p>Token name</p>     
          <input type="text" v-model="token.name">
          <p>Request per second</p>
          <select class="select" v-model="token.limit">
            <option :value="i" v-for="(i, index) in [0,10,30,50,100,200,1000,2000,5000]" :key="index">{{ i == 0 ? 'No limit' : i }}</option>
          </select>
          <div class="row center">
            <p class="button popupButtonColor" @click="createNewToken()">Create</p>
          </div>
        </div>
    </Popup>
  </teleport> -->


  </box>
    
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  props:{
    uid: Number,
  },
  mounted() {  
    this.refresh()
  },
  data() {
    return {  
      showNewApi: false,
      current: undefined,
      token:{
        name: undefined,
        limit: 0,
      },
      items:[],
    }
  },
  computed: {
    rules(){
      let list = {}
      this.dict.root.all.forEach(root => { list[root.id] = {}});

      if (!this.items || !this.items.length) return {}

      //items.roots[id] = action:bool
      this.items.forEach(item => {
        if (!item.rules || !item.rules.length) return
        
        item.rules.forEach(rule => {
          list[rule.root][rule.k] = rule.id
        })

      });
      return list
    },
  },
  
  methods: {

    async refresh(){      
      let res = await this.api.send('api/list', {uid: this.uid})
      console.log(res)
      if (res.error){
        console.log(res.error)        
        return
      }      
      this.items = res.resp
    },

    async createNewToken(){
      let res = await this.api.send('api/create', {uid: this.uid, name: this.token.name, limit: this.token.limit})
      console.log(res)
      if (res.error){
        console.log(res.error)        
        return
      }
      this.refresh()
      this.showNewApi = false
    },

    //id = token id
    async addRule(id, root, k){
      let res = await this.api.send('api/rule/add', {id, root, k})
      this.refresh()
    },
    
    async deleteRule(id){
      let res = await this.api.send('api/rule/delete', {id})
      this.refresh()
    },
    
  },
});

</script>
<style scoped>

.avatar{
  width: 200px;
  height: 200px;
  border-radius: 200px;
  background: var(--g01);
  
}

</style>