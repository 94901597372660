<template>
      <!-- <div class="text"> -->
          <input 
           
            class="input"
            :class="{top:top, clear:clear, bottom:bottom, top:borders, bottom:borders, iconpadding: icon}"
            :value="modelValue" 
            :disabled="disabled"
            @input="update($event.target.value)" 
            @keyup.enter.exact="updateValue"
            @keyup.meta.enter="updateValue"
            autocomplete="off" 
            autocorrect="off" 
            autocapitalize="off" 
            spellcheck="false"
            :placeholder="placeholder">
          <!-- <img v-if="icon" :src="icon" class="icon"> -->
      <!-- </div> -->

      <!-- <Input v-model="nice" ></Input> -->
</template>
<script>

export default {
    data: function () {return {
      timer: undefined,
    }},
    
    props: {
      modelValue: String,
      placeholder: String,
      top: Boolean,
      bottom: Boolean,
      borders: Boolean,
      icon: String,
      disabled: Boolean,
      class: String,
      clear: Boolean,

      delay: {type: Number, default:0},
    },

    methods: {
       update(v) {
        this.$emit('update:modelValue', v)
        if (this.delay > 0){
          this.change(v)
        } 
      },

      // input
      async wait(fn, delay = 300) {
            return ((...args) => {
              clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                this.timer = null
                fn(...args)
                }, delay)
            })()
      },

      updateValue(e) {
        console.log('input', e.target.value)
        this.wait(() => { this.$emit('update', e.target.value)}, this.delay);
      },
      


    },
}
</script>

<style scoped>
    .text{
      width: 100%;
      position: relative;
      padding: 0px 0;
      margin: 0;
    }
    .input{
      padding: 5px 12px 8px;
      margin: 0px 0 12px;
      border-radius: 8px;
      background: var(--g05);
    }
   
    .clear{
      background:none;
    }

    .icon{
      height: 14px;
      width: auto;
      position: absolute;
      top: 12px;
      left: 0px;
    }

    .iconpadding{
      padding-left: 26px;
    }

</style>

