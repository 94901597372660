<template>
    <p class="tag" :class="{active: id ? id == modelValue : false, special:special}" @click="click"><slot></slot></p>
</template>
<script>
  export default {
      data() {return {}},
      props:{
          id: undefined,
          modelValue: undefined,
          special: Boolean
        },        
        methods: {
            click(){            
                this.$emit('update:modelValue', this.id )
                this.$emit('update', this.id);
            }
        },
        
  }
</script>
