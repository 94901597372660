<template>
  

  
  <h1>{{account.name || 'New team' }}</h1>
  <p class="desc w500">{{account.email || 'Create a new team member with right access.'}}</p>
  <div class="column full">

    <!-- {{ account }} -->

          <!-- name -->
          <Block class="w300" title="Name" must>
            <input class="nomargin" type="text" v-model="account.name" placeholder="James Miller">
          </Block>

          <!-- email -->
          <Block  class="w300" title="Email" desc="Gmail accounts only!" must>
            <input class="nomargin" type="email" placeholder="some@gmail.com" v-model="account.email">
          </Block>

          <!-- location -->
          <Block class="w300" title="Location">
            <Geo 
              v-model="account.geoid" 
              @country="account.country = $event"
              @state="account.state = $event"
              @city="account.city = $event"
              placeholder="Location" class="w300 nomargin" height="300px"/>        
          </Block>

          <!-- tg -->
          <Block class="w300" title="Telegram ID">
            <input class="nomargin" type="number" placeholder="52413244" v-model.number="account.telegram">
            <p class="mini mt2">Ask team member to get an ID. <a href="https://t.me/getmyid_bot">https://t.me/getmyid_bot</a></p>
            
          </Block>
          
          <!-- wapp -->
          <Block class="w300" title="Whatsapp">
            <input class="nomargin" type="text" placeholder="+134552413244" v-model="account.whatsapp">
          </Block>

          <!-- dept -->
          <!-- <Block title="Dept" sub="Select user department">
            <div class="row wrap">
                <p class="tag" @click="account.dept = item" :class="{active: account.dept == item}" v-for="(item, index) in dept.list" :key="index">{{ dept.title[item] }}</p>
              </div>
          </Block> -->

          <!-- <Block title="Role" sub="Select user role">
            <div class="row wrap">
                <p class="tag" @click="account.dept = item" :class="{active: account.dept == item}" v-for="(item, index) in dept.list" :key="index">{{ dept.title[item] }}</p>
              </div>
          </Block> -->
          
                
          

          <!-- <Switch v-model="account.god"></Switch> -->

          <!-- active -->
          <Block class="w300" title="Access" sub="User can do anything in admin platform">            
            <div class="tabs nomargin" style="justify-content: flex-start; margin: 0;">
                <p class="tab" :class="{active: account.god}" @click="account.god = true">Full</p>
                <p class="tab" :class="{active: !account.god}" @click="account.god = false">Custom</p>
            </div>
            
             <!-- access -->
            <div class="row wrap w800 mt4" v-if="!account.god">              
              <Block :title="cat.title" style="margin-right: 20px; padding: 0 50px 0 0px; text-align: left; border-right: 1px solid var(--g1);" :sub="cat.sub" v-for="(cat, index) in access" :key="index">
                <div class="column" style="align-items: flex-start;justify-content: flex-start">
                  <Check class="pointer" style="margin: 4px 0;" small v-model="account.access[item.id]" v-for="(item, index) in cat.list" :key="index">{{ item.title }}</Check>                      
                </div>
              </Block>              
            </div>

          </Block>
          
          
          <!-- <Block v-if="!account.god" class="w300" title="Access" sub="You can choose access for user" must>            
            <Switch v-model="account.god"></Switch>
          </Block> -->

      

          

          <p v-if="error" class="bold" style="color: red; margin: 4px 0 ;" >{{ error }}</p>

          <br>
        
          <!-- buttons -->
          <div class="column">              
              <hr>                          
              <div class="row"><p class="button" style="margin: 0;" @click="createUser()">Create</p></div>      
              <br><br>
          </div>

    
          <code>
            {{ account }}
          </code>

    </div>

  


  

    
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  mounted() {  
  //  
    
  },
  data() {
    return {
      
      showCurrentAccess: false,
      error: undefined,      
      current: undefined,
      items:[], 

      account:{
        id: undefined, //int sql{inc}
        created: undefined, //int64 sql{unix} #readonly
        updated: undefined, //int64 sql{unix} #readonly
        logged: undefined, //int64 sql{unix} #readonly
        active: false, //bool #readonly
        god: false, //bool #readonly all access
        image: "", //string avatar
        name: "", //string James
        line: "", //string CEO, founder
        dept: "", //string #readonly support, dev, design, top
        role: "", //string #readonly moderator
        email: "", //string #readonly sql{unique="creds" index}
        phone: "", //string 
        whatsapp: "", //string 
        github: "", //string 
        facebook: "", //string 
        insta: "", //string 
        link: "", //string 
        telegram: undefined, //int #readonly
        country: "", //string us #readonly
        state: "", //string ca #readonly
        city: "", //string London #readonly
        timezone: "", //string London #readonly
        geoid: undefined, //int 
        access: {}, //map[string]bool #readonly
        meta: {}, //map[string]any #readonly
      },

      dept:{
        list:['dev','design','market','exe','fin','other'],
        title:{ dev:'Development', design:'Design', market:'Marketing', exe:'Executive', fin:'Finance', other:'Other'},
      },
      
        
      access: [
          {
            title:'Accounts',
            sub:'',
            list: [
              {id:'accounts/search', title:'Search', sub:'Search all accounts with emails'},
              {id:'accounts/create', title:'Create', sub:'User can create an account'},              
              {id:'accounts/update', title:'Update', sub:'Update any account'},
              {id:'accounts/delete', title:'Delete', sub:'Delete any account'},
            ]
          },
          {
            title:'Assets',
            sub:'',
            list:[
              {id:'assets/search', title:'Search', sub:'Search all assets'},            
              {id:'assets/create', title:'Create', sub:'Create assets'},            
              {id:'assets/update', title:'Update', sub:'Update assets'},
              {id:'assets/delete', title:'Delete', sub:'Delete assets'},
              {id:'assets/review', title:'Review', sub:'Review and moderate assets'},
            ]
          },          
          {
            title:'Support',
            sub:'',
            list:[
              {id:'support/tickets', title:'Tickets', sub:'Answer user tickets'},            
              {id:'support/emails', title:'Emails', sub:'User emails access'},            
              {id:'support/chat', title:'Chat', sub:'User support chat'},              
            ]
          },          
         
        ]
           
    }
  },
  computed: {
    users(){
      // if (!this.items) return []
      let list = []
      
      let me = this.items.filter(p => p.id == this.db.profile.id)            
      let active = this.items.filter(p => p.id != this.db.profile.id && p.active)
      let inactive = this.items.filter(p => !p.active)

      list.push(...me)
      list.push(...active)      
      list.push(...inactive)      
      return list
    },    
   
  },
  
  methods: {
    
   
    // create user
    async createUser(){
      if (!this.account.email) {
        this.error = 'Email is empty'
        return
      }
      if (!this.account.email.endsWith('gmail.com') && !this.account.email.endsWith('stima.io')) {
        this.error = 'gmail.com or stima.io emails only'
        return
      }
      
      if (!this.account.god && !Object.keys(this.account.access).length) {
        this.error = 'Select some access for an account....'        
        return
      }

      let res = await this.api.send('team/create',this.account)
      console.log('ress',res)
      if (res.error){
        this.error = res.error.code + ': '+ res.error.id
        this.$emit('error', res.error)
        return
      }

      this.$emit('update', true)
      
    },



    
  },
});

</script>
<style scoped>
body{
  overflow-y: scroll ;
}
.subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.0em;
  color: var(--g4);
  margin: 10px 0;
  /* margin-left: -5px; */

}

.tabs {
  margin: 0 10px;
}

.tableimage{
  width: 20px;
  height: 20px;
  background: none;
  border: 1px solid var(--g2);
  border-radius: 100px;
}

.jsonpre{
  font-family: monospace;
  font-size: 11px;
  line-height: 15px;
}

</style>