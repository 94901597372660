<template>
     <div class="column inputBox" style="position: relative;">
      
      
          <img v-if="country && !round" class="icon" :src="`/images/icons/flags/rect/${country}.svg`" >
          <img v-if="country && round" class="roundicon" :src="`/images/icons/flags/round/${country}.svg`" >
          <input  
              style="margin: 0;transition: none;"              
              ref="input"
              :class="{inputIcon: current.id, noBottomBorder: items.length, round, inputIconRound:round && current.id}"                           
              type="text"               
              v-model="searchText"
              :placeholder="placeholder || 'Location'"              
              @keydown.down="focusHandler" 
              @keydown.up="focusHandler" 
              @keydown.enter.prevent="focusHandler"
              @input="search">
          
              
          <!-- clear -->
          <div class="clearicon pointer click" v-if="searchText && !round" @click="clear()"><span>×</span></div>
          <div class="cleariconround pointer click" v-if="searchText && round" @click="clear()"><span>×</span></div>
          
          <!-- list -->
            <div class="autocomplete" v-out="out" :style="{height, width: `${$refs.input.clientWidth}px`, marginTop: `${$refs.input.clientHeight}px`}" v-if="items.length">
              <div class="itembox row ch"  @click="current = item; update()" v-for="(item, index) in items" :key="index" :class="{ active: index === focus }">                
                  <img style="width: 20px; height: 20px" :src="`/images/icons/flags/round/${item.country}.svg`">
                  <!-- <p class="item">{{ item.name }}</p> -->
                  <p class="item" :class="{rounditem: round}" v-if="item.country == 'us' && item.state" >{{ item.name }}, {{ item.state.toUpperCase() }}</p>  
                  <p class="item" :class="{rounditem: round}" v-else>{{ item.name }}, {{ countryTitle[item.country] }}</p>                
              </div>
            </div>          
        </div>

        <!-- 

          <GeoInput v-model="geoid" placeholder="Location" class="w300" height="300px"/>
         -->
</template>
<script>
export default {
   
    data: function () {return {
      current:{},
      timer: undefined,
      items: [],
      focus: null,
      searchText: undefined,
      countryTitle:{
        "af": "Afghanistan",
        "ax": "Åland Islands",
        "al": "Albania",
        "dz": "Algeria",
        "as": "American Samoa",
        "ad": "Andorra",
        "ao": "Angola",
        "ai": "Anguilla",
        "aq": "Antarctica",
        "ag": "Antigua & Barbuda",
        "ar": "Argentina",
        "am": "Armenia",
        "aw": "Aruba",
        "au": "Australia",
        "at": "Austria",
        "az": "Azerbaijan",
        "bs": "Bahamas",
        "bh": "Bahrain",
        "bd": "Bangladesh",
        "bb": "Barbados",
        "by": "Belarus",
        "be": "Belgium",
        "bz": "Belize",
        "bj": "Benin",
        "bm": "Bermuda",
        "bt": "Bhutan",
        "bo": "Bolivia",
        "ba": "Bosnia & Herzegovina",
        "bw": "Botswana",
        "bv": "Bouvet Island",
        "br": "Brazil",
        "io": "British Indian Ocean Territory",
        "vg": "British Virgin Islands",
        "bn": "Brunei",
        "bg": "Bulgaria",
        "bf": "Burkina Faso",
        "bi": "Burundi",
        "kh": "Cambodia",
        "cm": "Cameroon",
        "ca": "Canada",
        "cv": "Cape Verde",
        "bq": "Caribbean Netherlands",
        "ky": "Cayman Islands",
        "cf": "Central African Republic",
        "td": "Chad",
        "cl": "Chile",
        "cn": "China",
        "cx": "Christmas Island",
        "cc": "Cocos (Keeling) Islands",
        "co": "Colombia",
        "km": "Comoros",
        "cg": "Congo - Brazzaville",
        "cd": "Congo - Kinshasa",
        "ck": "Cook Islands",
        "cr": "Costa Rica",
        "ci": "Côte d’Ivoire",
        "hr": "Croatia",
        "cu": "Cuba",
        "cw": "Curaçao",
        "cy": "Cyprus",
        "cz": "Czechia",
        "dk": "Denmark",
        "dj": "Djibouti",
        "dm": "Dominica",
        "do": "Dominican Republic",
        "ec": "Ecuador",
        "eg": "Egypt",
        "sv": "El Salvador",
        "gq": "Equatorial Guinea",
        "er": "Eritrea",
        "ee": "Estonia",
        "sz": "Eswatini",
        "et": "Ethiopia",
        "fk": "Falkland Islands",
        "fo": "Faroe Islands",
        "fj": "Fiji",
        "fi": "Finland",
        "fr": "France",
        "gf": "French Guiana",
        "pf": "French Polynesia",
        "tf": "French Southern Territories",
        "ga": "Gabon",
        "gm": "Gambia",
        "ge": "Georgia",
        "de": "Germany",
        "gh": "Ghana",
        "gi": "Gibraltar",
        "gr": "Greece",
        "gl": "Greenland",
        "gd": "Grenada",
        "gp": "Guadeloupe",
        "gu": "Guam",
        "gt": "Guatemala",
        "gg": "Guernsey",
        "gn": "Guinea",
        "gw": "Guinea-Bissau",
        "gy": "Guyana",
        "ht": "Haiti",
        "hm": "Heard & McDonald Islands",
        "hn": "Honduras",
        "hk": "Hong Kong SAR China",
        "hu": "Hungary",
        "is": "Iceland",
        "in": "India",
        "id": "Indonesia",
        "ir": "Iran",
        "iq": "Iraq",
        "ie": "Ireland",
        "im": "Isle of Man",
        "il": "Israel",
        "it": "Italy",
        "jm": "Jamaica",
        "jp": "Japan",
        "je": "Jersey",
        "jo": "Jordan",
        "kz": "Kazakhstan",
        "ke": "Kenya",
        "ki": "Kiribati",
        "kw": "Kuwait",
        "kg": "Kyrgyzstan",
        "la": "Laos",
        "lv": "Latvia",
        "lb": "Lebanon",
        "ls": "Lesotho",
        "lr": "Liberia",
        "ly": "Libya",
        "li": "Liechtenstein",
        "lt": "Lithuania",
        "lu": "Luxembourg",
        "mo": "Macao SAR China",
        "mg": "Madagascar",
        "mw": "Malawi",
        "my": "Malaysia",
        "mv": "Maldives",
        "ml": "Mali",
        "mt": "Malta",
        "mh": "Marshall Islands",
        "mq": "Martinique",
        "mr": "Mauritania",
        "mu": "Mauritius",
        "yt": "Mayotte",
        "mx": "Mexico",
        "fm": "Micronesia",
        "md": "Moldova",
        "mc": "Monaco",
        "mn": "Mongolia",
        "me": "Montenegro",
        "ms": "Montserrat",
        "ma": "Morocco",
        "mz": "Mozambique",
        "mm": "Myanmar (Burma)",
        "na": "Namibia",
        "nr": "Nauru",
        "np": "Nepal",
        "nl": "Netherlands",
        "nc": "New Caledonia",
        "nz": "New Zealand",
        "ni": "Nicaragua",
        "ne": "Niger",
        "ng": "Nigeria",
        "nu": "Niue",
        "nf": "Norfolk Island",
        "kp": "North Korea",
        "mk": "North Macedonia",
        "mp": "Northern Mariana Islands",
        "no": "Norway",
        "om": "Oman",
        "pk": "Pakistan",
        "pw": "Palau",
        "ps": "Palestinian Territories",
        "pa": "Panama",
        "pg": "Papua New Guinea",
        "py": "Paraguay",
        "pe": "Peru",
        "ph": "Philippines",
        "pn": "Pitcairn Islands",
        "pl": "Poland",
        "pt": "Portugal",
        "pr": "Puerto Rico",
        "qa": "Qatar",
        "re": "Réunion",
        "ro": "Romania",
        "ru": "Russia",
        "rw": "Rwanda",
        "ws": "Samoa",
        "sm": "San Marino",
        "st": "São Tomé & Príncipe",
        "sa": "Saudi Arabia",
        "sn": "Senegal",
        "rs": "Serbia",
        "sc": "Seychelles",
        "sl": "Sierra Leone",
        "sg": "Singapore",
        "sx": "Sint Maarten",
        "sk": "Slovakia",
        "si": "Slovenia",
        "sb": "Solomon Islands",
        "so": "Somalia",
        "za": "South Africa",
        "gs": "South Georgia & South Sandwich Islands",
        "kr": "South Korea",
        "ss": "South Sudan",
        "es": "Spain",
        "lk": "Sri Lanka",
        "bl": "St. Barthélemy",
        "sh": "St. Helena",
        "kn": "St. Kitts & Nevis",
        "lc": "St. Lucia",
        "mf": "St. Martin",
        "pm": "St. Pierre & Miquelon",
        "vc": "St. Vincent & Grenadines",
        "sd": "Sudan",
        "sr": "Suriname",
        "sj": "Svalbard & Jan Mayen",
        "se": "Sweden",
        "ch": "Switzerland",
        "sy": "Syria",
        "tw": "Taiwan",
        "tj": "Tajikistan",
        "tz": "Tanzania",
        "th": "Thailand",
        "tl": "Timor-Leste",
        "tg": "Togo",
        "tk": "Tokelau",
        "to": "Tonga",
        "tt": "Trinidad & Tobago",
        "tn": "Tunisia",
        "tr": "Turkey",
        "tm": "Turkmenistan",
        "tc": "Turks & Caicos Islands",
        "tv": "Tuvalu",
        "um": "U.S. Outlying Islands",
        "vi": "U.S. Virgin Islands",
        "ug": "Uganda",
        "ua": "Ukraine",
        "ae": "UAE",
        "uk": "UK",
        "us": "US",
        "uy": "Uruguay",
        "uz": "Uzbekistan",
        "vu": "Vanuatu",
        "va": "Vatican City",
        "ve": "Venezuela",
        "vn": "Vietnam",
        "wf": "Wallis & Futuna",
        "eh": "Western Sahara",
        "ye": "Yemen",
        "zm": "Zambia",
        "zw": "Zimbabwe",     
      }
    }},

    
    props: {
      modelValue: Number,
      placeholder: String,           
      height: {type:String, default:'300px'},      
      delay: {type: Number, default:0},
      round:Boolean,
    },

    mounted() {
      if (this.modelValue){
        // 
      }
    },

    computed: {
      value(){        
        return `${this.current.name}, ${this.countryTitle[this.current.country]}`
      },

      country(){        
        return this.current.country
      }
    },

    methods: {
      keydowns(){
        console.log('key enter')
      },
      focusHandler(event) {
        switch (event.keyCode) {
          case 13:
            if (!this.focus) {
              this.current = this.items[0]
              this.update()
              return
            }             
              this.current = this.items[this.focus]
              this.update()            
              return
          case 38:
            if (this.focus === null) {
              this.focus = 0;
            } else if (this.focus > 0) {
              this.focus--;
            }
            break;
          case 40:
            if (this.focus === null) {
              this.focus = 0;
            } else if (this.focus < this.items.length - 1) {
              this.focus++;
            }
            break;
        }
      },

      out(){
        console.log('outside')
        this.items=[]
        // this.clear()
      },
      clear(){
        this.current = {}
        this.searchText = undefined
        this.$emit('update:modelValue', undefined)  
        this.$emit('update', undefined)        
        this.$emit('country', undefined)
        this.$emit('city', undefined)
        this.$emit('state', undefined)
        this.items = []
      },
      
       update() {
        this.$emit('update:modelValue', this.current.id)  
        this.$emit('update', this.current)
        this.$emit('country', this.current.country)
        this.$emit('city', this.current.name)
        this.$emit('state', this.current.state)
        if (this.current.country == 'us' && this.current.state){
          this.searchText = `${this.current.name}, ${this.current.state.toUpperCase()}`        
        } else{
          this.searchText = `${this.current.name}, ${this.countryTitle[this.current.country]}`        
        }
        
        // this.dict.cities[this.current.id] = this.current
        this.items = []
      },

      // input
      async wait(fn, delay = 300) {
            return ((...args) => {
              clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                this.timer = null
                fn(...args)
                }, delay)
            })()
      },

      updateValue(e) {
        // console.log('input', e.target.value)
        // this.wait(() => { }, this.delay);
      },



      async search(){
        let res = await this.api.send('geo', {q: this.searchText})
        
      console.log(res)
      if (res.error){
        console.log(res.error)        
        return
      }
      this.items = res.resp
      /* {
		    "country": "it",
		    "flag": "🇮🇹",
		    "id": 3171457,
		    "name": "Parma"
		  }, */
    },


    },
}
</script>

<style scoped>
.item {
  font-size: 15px;
  margin-top: -1px;
  margin-left: 2px;
}
.rounditem {
  font-size: 13px;
  margin-top: -1px;
  margin-left: 2px;
}

.inputBox {
  margin: 0px 0 12px;
  margin: 0px 0 0px;
}

.round{
  padding: 3px 16px; 
  border-radius: 50px; 
  font-size: 13px; 
  width: 100%;
}

/* input */
.autocomplete {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--back);
  position: absolute;
  z-index: 1000;
  /* margin-top: 30px; */
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px dashed var(--g1);
}

.itembox {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  /* background-color: var(--back); */
  background: var(--input);
  padding: 2px 0px 2px 6px;
}

.itembox:hover, .itembox.active {
  background-color: var(--g1);
}

/* .autocomplete div p {
  padding: 2px 8px;
  text-align: left;
} */

.noBottomBorder {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.round.noBottomBorder {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.text {
  width: 100%;
  position: relative;
  padding: 0px 0;
  margin: 0;
}

.input {
  padding: 5px 12px 8px;
  border-radius: 8px;
  background: var(--g05);
}

.clear {
  background: none;
}

.inputIcon {
  padding-left: 50px;
}

.icon {
  height: 20px;
  width: auto;
  position: absolute;
  top: 11px;
  left: 12px;
  border-radius: 4px;
}

.inputIconRound {
  padding-left: 38px;
}

.roundicon {
  height: 18px;
width: auto;
position: absolute;
top: 7px;
left: 9px;
border-radius: 4px;
}

.clearicon {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  position: absolute;
  top: 11px;
  right: 7px;
  border-radius: 100px;
  background-color: var(--g1);
}

.clearicon span {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  color: var(--g3);
  margin-top: -1px;
}

.cleariconround {
  cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
display: flex;
align-items: center;
justify-content: center;
height: 17px;
width: 17px;
position: absolute;
top: 7px;
right: 10px;
border-radius: 100px;
background-color: var(--g1);
}

.cleariconround span {
  position: absolute;
font-size: 12px;
font-weight: 500;
color: var(--g3);
margin-top: -1px;
margin-left: 1px;
}

.iconpadding {
  padding-left: 30px;
}

div.focus {
  border: 1px solid blue;
}

</style>

